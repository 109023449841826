import React from 'react'
import { RevisorFunctions } from '../../lib/apiFunctions'
import { ComplianceReportsTypes } from '../../lib/enums'
import { User } from '../../types/session'
import UseTaxYears from '../ComplianceReports/useTaxYears'

export default function RevisorPage() {
    async function getUser(LLUserId: string): Promise<User> {
        return (new RevisorFunctions()).getLLUserData(LLUserId)
    }
    return <UseTaxYears contentType={ComplianceReportsTypes.Revisor} getUserData={getUser} />
}
